import { CreateActionParams } from '../actions';
import { CouponsErrorType } from '../../../../types/errors';
import { CouponInfo } from '../../../../types/coupons';

export type RemoveCoupon = () => void;

export function createRemoveCouponAction({
  getControllerState,
  internalActions,
}: CreateActionParams): RemoveCoupon {
  return async () => {
    const [state, setState] = getControllerState();
    const { errorHandlers } = internalActions;

    errorHandlers.clearErrorByTypes([CouponsErrorType]);

    setState({
      couponInfo: getCouponInfoWithoutAppliedCoupon(state.couponInfo),
    });
  };
}

export function getCouponInfoWithoutAppliedCoupon(couponInfo: CouponInfo) {
  return {
    areCouponsAvailable: couponInfo.areCouponsAvailable,
    isCouponInputDisplayed: couponInfo.isCouponInputDisplayed,
  };
}

import { CreateActionParams } from '../actions';

export type SetPaymentOption = (selectedPaymentOptionId: string) => void;

export function createSetPaymentOptionAction({
  getControllerState,
}: CreateActionParams): SetPaymentOption {
  return async (selectedPaymentOptionId: string) => {
    const [, setState] = getControllerState();

    setState({ selectedPaymentOptionId });
  };
}

import { FormStatus } from '../../../../utils/state/initialStateFactory';
import { CreateActionParams } from '../actions';

export type InitializeWidget = () => void;

export function createInitializeWidgetAction({
  getControllerState,
}: CreateActionParams): InitializeWidget {
  return () => {
    const [, setState] = getControllerState();

    setState({
      status: FormStatus.IDLE,
    });
  };
}

import {
  FormState,
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { SubmissionResponse } from '@wix/forms-ui/types';
import { widgetDefaults } from '../../../../utils/bi/consts';
import { mapSubmissionToPartialBookRequest } from '../../../../utils/mappers/form-submission.mapper';
import { DialogType, ReservedPaymentOptionIds } from '../../../../types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { Service } from '../../../../utils/mappers/service.mapper';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  getSessionValues,
  setFieldsValuesInStorage,
} from '../../../../utils/storageFunctions';
import { CreateActionParams } from '../actions';
import {
  bookingsContactInfoSaveSuccess,
  bookingsPaymentMethodSelectionNextClicked,
} from '@wix/bi-logger-wixboost-ugc/dist/src/v2';
import { BookErrorType } from '../../../../types/errors';

export type OnSubmit = (submission: SubmissionResponse) => void;

export function createOnSubmitAction({
  getControllerState,
  context,
  internalActions,
}: CreateActionParams): OnSubmit {
  return async (submissionResponse) => {
    const [state, setState] = getControllerState();
    const { wixSdkAdapter, formApi, biLogger, t } = context;
    const { errorHandlers } = internalActions;
    const {
      service,
      businessInfo,
      slotAvailability,
      selectedPaymentOptionId,
      pricingPlanDetails,
      couponInfo,
      status,
    } = state;
    if (status !== FormStatus.IDLE) {
      return;
    }

    const isPreviewMode = wixSdkAdapter.isPreviewMode();
    const isOwner = wixSdkAdapter.isOwner();
    if (isOwner && !isPreviewMode) {
      setState(
        getOwnerSubmitDialog({
          contentText: t('app.dialog.owner-submit.content'),
          confirmButtonText: t('app.dialog.owner-submit.confirm-button'),
        }),
      );
      return;
    }

    if (selectedPaymentOptionId === ReservedPaymentOptionIds.BuyAPricingPlan) {
      setFieldsValuesInStorage(wixSdkAdapter, submissionResponse);
      return purchasePricingPlan(service, wixSdkAdapter, slotAvailability);
    }

    const { formInfo, sendSmsReminder } = mapSubmissionToPartialBookRequest(
      submissionResponse.submission,
      service,
      businessInfo,
    );

    if (submissionResponse.state.valid) {
      setState({
        status: FormStatus.PROCESSING_BOOK_REQUEST,
      });

      if (isPreviewMode) {
        return wixSdkAdapter.navigateToBookingsWithSuffix();
      }
      setFieldsValuesInStorage(wixSdkAdapter, submissionResponse);
      const selectedPlan = pricingPlanDetails?.plans?.find(
        (plan) => plan?.paidPlan?.orderId === selectedPaymentOptionId,
      );

      try {
        const bookingResponse = await formApi.book({
          service,
          formInfo,
          slotAvailability,
          selectedPlan,
          sendSmsReminder,
          appliedCoupon: couponInfo.appliedCoupon,
        });

        biLogger?.report(
          bookingsContactInfoSaveSuccess({
            smsNotificationRequest: sendSmsReminder,
          }),
        );

        return wixSdkAdapter.navigateToBookingsCheckout(
          bookingResponse.booking!,
          widgetDefaults.pageName,
        );
      } catch (error) {
        errorHandlers.addError(error as BookErrorType);
        setState({ status: FormStatus.IDLE });
        biLogger?.report(
          bookingsPaymentMethodSelectionNextClicked({
            errorMessage: JSON.stringify(error),
          }),
        );
      }
    }
  };
}

function getOwnerSubmitDialog({
  contentText,
  confirmButtonText,
}: {
  contentText: string;
  confirmButtonText: string;
}): Partial<FormState> {
  return {
    dialog: {
      type: DialogType.OwnerSubmit,
      props: {
        isOpen: true,
        contentText,
        confirmButtonText,
      },
    },
  };
}

function purchasePricingPlan(
  service: Service,
  wixSdkAdapter: WixOOISDKAdapter,
  slotAvailability: SlotAvailability,
) {
  const planIds = service.payment.pricingPlanInfo?.pricingPlans.map(
    (plan) => plan.id,
  );
  const { referral } = getQueryParams(wixSdkAdapter);
  const timezone = getSessionValues(
    wixSdkAdapter,
    BookingsQueryParams.TIMEZONE,
  );

  return wixSdkAdapter.navigateToPricingPlan({
    redirectTo: { sectionId: 'Booking Form', relativePath: '' },
    planIds: planIds!,
    maxStartDate: slotAvailability.slot?.startDate!,
    queryParams: {
      referral,
      timezone,
      service: service.id,
    },
  });
}

export function getQueryParams(wixSdkAdapter: WixOOISDKAdapter): {
  [key: string]: string;
} {
  const referral = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.REFERRAL,
  );
  return { referral };
}

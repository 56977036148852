import { FormStatus } from '../../../../utils/state/initialStateFactory';
import { CreateActionParams } from '../actions';

export type SetNumberOfParticipants = (numberOfParticipants: number) => void;

export function createSetNumberOfParticipantsAction({
  getControllerState,
  internalActions,
}: CreateActionParams): SetNumberOfParticipants {
  return async (numberOfParticipants: number) => {
    const [, setState] = getControllerState();
    const { calculatePaymentDetails } = internalActions;

    setState({
      numberOfParticipants,
      status: FormStatus.PROCESSING_PAYMENT_DETAILS,
    });

    calculatePaymentDetails();
  };
}

import { CreateActionParams } from '../actions';

export type OnToggleCoupon = () => void;

export function createOnToggleCouponAction({
  getControllerState,
}: CreateActionParams): OnToggleCoupon {
  return async () => {
    const [state, setState] = getControllerState();

    setState({
      couponInfo: {
        ...state.couponInfo,
        isCouponInputDisplayed: !state.couponInfo.isCouponInputDisplayed,
      },
    });
  };
}

import { FormStatus } from '../../../../utils/state/initialStateFactory';
import { CreateActionParams } from '../actions';

export type PromptLogin = () => void;

export function createPromptLoginAction({
  getControllerState,
  context: { wixSdkAdapter },
}: CreateActionParams): PromptLogin {
  return async () => {
    const [, setState] = getControllerState();
    try {
      setState({
        status: FormStatus.PROCESSING_USER_DETAILS,
      });
      await wixSdkAdapter.promptUserLogin();
    } catch (e) {
      setState({
        status: FormStatus.IDLE,
      });
    }
  };
}
